.buttonWithModal {
  &__buttonNumber {
    border-radius: 500px;
    display: inline-block;
    text-align: center;
    height: 40px;
    width: 40px;
    font-size: 16px;
    background-color: #1790fd;
    color: white;
    margin: 2px 8px 2px 0;
    padding: 9px 3px;

    &--simple {
      display: inline-block;
      height: 40px;
      font-size: 16px;
      color:  #1790fd;
      font-weight: bold;
      background-color: white;
      margin: 2px 8px 2px 0;
      padding: 9px 3px;
    }
  }
}
