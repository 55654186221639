.studentOverview {
  text-align: center;
  &__coming {
    border: 1px solid rgb(240, 240, 240);
    padding: 8px;
    background-color: rgb(247, 247, 247);
    border-radius: 4px;
    margin: 10px 0;
    font-size: 16px;
  }
  &__booksOwned {
    margin: 10px 0;
    border: 1px solid rgb(240, 240, 240);
    padding: 10px;
    background-color: rgb(247, 247, 247);
    border-radius: 4px;
    &__books {
      text-wrap: wrap;
      color: #b99022;
    }
  }
  &__photoRelease {
    margin: 10px 0;
    border: 1px solid rgb(240, 240, 240);
    padding: 10px;
    background-color: rgb(247, 247, 247);
    border-radius: 4px;
  }
  &__startDate {
    margin: 10px 0;
    border: 1px solid rgb(240, 240, 240);
    padding: 8px;
    background-color: rgb(247, 247, 247);
    border-radius: 4px;
  }

  &__flexAmounts {
    margin: 10px 0;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(240, 240, 240);
    &__card {
      &--number {
        font-size: 20px;
      }
      &--title {
        font-size: 14px;
      }
      &--detail {
        font-size: 12px;
      }
    }
  }
}
