//colours

$sky: #1790fd;
$mustard: #b99022;
$white: #ffffff;
$dark: #111111;
$dark2: #222222;
$roomPlanToday: #006edc;
$roomPlanLightGray: #f8f8f8;
$roomPlanSerial: #474747;
$roomPlanLesson: #CCCCCC;
$roomPlanAvail: #C5DE97;
$roomPlanLogs: #EE863C;
