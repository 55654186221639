.ant-form-large .ant-form-item-label > label {
  height: 20px;
}

.ant-form-item-label {
  font-weight: bold !important;
}

.foo {
  display: none;
  opacity: 0;
  animation: fade-out 1s 1;
}
.foo.fade-in {
  display: block;
  opacity: 1;
  animation: fade-in 0.5s 1;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px !important;
}

.dynamic-delete-button {
  margin-left: 20px;
}

.form-submit-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.form-button-right {
  float: right;
}

.form-button-left {
  float: left;
}

.buttonWithFloatRounded {
  &__buttons {
    &--navigation {
      margin: 20px 0px;
      &--floatLeft {
        float: left;
        border-radius: 20px;
      }
      &--floatRight {
        float: right;
        border-radius: 20px;
      }
    }
  }
}

.buttonInModalWithFloatRounded {
  &__buttons {
    &--floatLeft {
      border-radius: 20px;
      color: #ff4d4f;
      border-color: #ff4d4f;
      position: absolute;
      left: 15px;
      &:hover {
        cursor: pointer;
        color: #ff4d4f;
        border-color: #ff4d4f;
        opacity: 0.7;
      }
    }
    &--floatRight {
      border-radius: 20px;
    }
  }
}

.form {
  &__index {
    display: inline-block;
    margin-right: 10px;
  }
  &__asyncFieldWrapper {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      > div {
        width: 100%;
      }
      > span {
        margin-left: 10px;
        margin-top: -10px;
      }
    }
  }

  &__asyncField {
    width: calc(100% - 150px);
  }

  &__labelWithButton {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin: 10px 0;
  }
}
