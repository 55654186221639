.breadcrumb {
  margin:10px 10px 0;

  .ant-breadcrumb-link {
    font-size: 16px;
  }

  .icon__home {
    font-size: 18px;
  }
}
