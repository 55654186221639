.toolbar {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 10px;

  button {
    margin-left: 20px;
  }

  &__left {
    display: flex;
    align-content: center;
  }

  &__right {
    display: flex;
    align-content: center;
  }
}
.toolbarMobile {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 10px;

  button {
    margin-left: 10px;
  }

  &__left {
    display: flex;
    align-content: center;
  }

  &__right {
    display: flex;
    align-content: center;
    margin-top: 10px;
  }
}
