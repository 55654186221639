@import "src/styles/vars";
#root,
body,
html {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #f0f2f5;
}

.ant-table {
  overflow-y: scroll;
}

.ant-menu-item-group-title {
  color: white !important;
  font-size: 22px;
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  overflow: visible;
}
.amount {
  display: block;
  text-align: right;
}

.cardBasic,
.cardChart {
  margin: 5px 0;
  padding: 5px 0;
  text-align: left;
  border-radius: 2px;

  &--heading {
    color: rgb(148, 148, 148);
    font-size: 1.1em;
  }

  &--body {
    margin-top: 15px;
  }

  &--value {
    font-size: 1.8em;
    font-weight: 600;
  }
  &--text {
    font-size: 1em;
    margin-bottom: 0px;
  }
  &--subText {
    font-size: 12px;
  }
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #cccccc;
}

.stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 10px;
  width: 100%;
  &__item {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin: 10px 10px;
    padding: 10px 20px;
    width: 45%;
    background-color: white;
    // height: 70px;
    overflow: hidden;

    @media only screen and (max-width: 1300px) {
      // height: 90px;
      text-align: center;
    }

    @media only screen and (max-width: 1000px) {
      width: 100%;
      height: auto;
      text-align: center;
    }
    &__title {
      font-size: 16px;
      &__number {
        color: #ff5d5b;
        font-weight: bold;
        font-size: 18px;
      }
    }
    &__percentage {
      border: 1px solid #1790fd;
      padding: 3px 5px;
      border-radius: 5px;
      color: #1790fd;
      white-space: nowrap;
      overflow: visible;
    }
  }
}

.warningBox {
  color: red;
  border: 1px solid red;
  padding: 10px;
  margin: 10px 0;
  border-radius: 2px;
}

.ant-menu-item-group-title {
  font-size: 17px;
  text-align: center;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
}

.table__clickableRows:hover {
  cursor: pointer;

  opacity: 0.8;
}

pre {
  font-size: 14px;
  font-family: sans-serif;
  max-width: 100%;
  white-space: pre-wrap;
  overflow: auto;
  word-wrap: break-word;
}

.table-notes {
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
