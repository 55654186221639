.prospectHistory {
  &__title {
    font-size: 17px;
    //color: rgb(255, 88, 88);
    margin: 15px 0;
  }
  &__item {
    font-size: 16px;
    margin-bottom: 6px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.719);
    &--highlighted {
      font-weight: bold;
    }
  }
}
