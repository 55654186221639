.contractInfo {
  &__profile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &__avatar {
      margin-right: 20px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      &--highlighted {
        font-weight: bold;
      }
    }
  }

  &__overview {
    &__title {
      font-size: 20px;
      color: #fd1717;
      margin: 15px 0;
    }
    &__item {
      font-size: 16px;
      margin-bottom: 6px;
      border-bottom: 1px solid rgba(216, 216, 216, 0.719);
      &--highlighted {
        font-weight: bold;
      }
    }
  }
}
