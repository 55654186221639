@import "src/styles/vars";

.export {
  &__filterTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__filters {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--static {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin-bottom: 10px;

      > input {
        margin-right: 10px;
        display: flex;
        align-items: center;
        flex: 1;
      }

      > div {
        flex: 1;
      }
    }

    &--dynamic {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__filter {
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &__content {
    margin: 20px 0 30px 0;
  }

  &__file {
    // text-align: right;
    font-weight: bold;
  }
}

.threeCard {
  &__title {
    font-size: 24px;
    text-align: center;
    &--icon {
      font-size: 26px;
    }
  }
  &__number {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: $mustard;
    &__small {
      font-size: 24px;
      font-weight: bold;
      color: $mustard;
    }
  }

  &__miniTitle {
    color: rgb(148, 148, 148);
  }

  &__vsStats {
    margin-left: 5px;
    font-size: 18px;
    color: rgb(148, 148, 148);
  }
}

.red {
  color: red !important;
}
.orange {
  color: #ff9100 !important;
}
.yellow {
  color: rgb(211, 211, 9) !important;
}
.green {
  color: green !important;
}

.statsCard {
  &__title {
    font-size: 24px;
    text-align: center;
    height: 70px;
    white-space: pre-wrap;
  }
}

.stats {
  display: flex;
  text-align: center;
}

.statsDiff {
  &__negative {
    color: red;
    text-align: center;
    font-size: 20px;
  }

  &__positive {
    text-align: center;
    font-size: 20px;
    color: green;
    &__large {
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      color: green;
    }
  }
}

.viewInfo {
  position: relative;
  //top: 38px;
  right: 30px;
  margin-left: 10px;
  font-size: 15px;
  color: #b99022;
  font-size: 22px;
  cursor: pointer;
}

.statsInfoText {
  white-space: pre;
  display: inline-block;
}
