.prospectProfile,
.userTabs {
  background-color: white;
  padding: 10px 10px;
  margin: 5px;
  border-radius: 10px;
}

.prospectProfile {
  &__profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__avatar {
      margin: 10px 0;
    }
    &__fullName {
      max-width: 90%;
      font-size: 1.5em;
      font-weight: 500;
      color: black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__status {
      margin-top: 20px;
    }
  }

  &__infoList {
    list-style-type: none;
    width: 90%;
    &__infoText {
      font-size: 1.1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__icon {
      margin: 0 6px;
      font-size: 1.1em;
    }
  }

  &__tags {
    &__text {
      font-size: 1.2em;
    }
    &__tag {
      margin: 4px 3px;
      font-size: 1.1em;
      cursor: pointer;
    }
  }
  &__overview {
    max-width: 100% !important;
    font-size: 16px;

    &__noTrial {
      padding: 8px !important;
      color: #1d39c4;
      background: #f0f5ff;
      border: 1px solid #adc6ff;
    }
  }
  &__notes {
    font-size: 16px;
    margin-top: 10px;
  }
}

.interview {
  &__question {
    font-size: 16px;
    font-weight: bold;
    color: rgb(53, 52, 52);
    margin: 10px 0 5px 0;
  }
  &__answer {
    &__normal {
      font-size: 16px;
    }
    &__array {
      font-size: 16px;
    }
  }
}
