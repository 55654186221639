.prospectInfo {
    &__profile {
      display: flex;
      justify-content: center;
      align-items: center;
      &__avatar {
        margin-right: 20px;
      }
      &__info {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        &--highlighted {
          color: #1790fd;
        }
      }
    }
  
    &__overview {
      &__title {
        font-size: 20px;
        color: #fd1717;
        margin: 15px 0;
      }
      &__item {
          font-size: 16px;
        margin-bottom: 6px;
        border-bottom: 1px solid rgba(216, 216, 216, 0.719);
        &--highlighted {
          color: #1790fd;
        }
      }
    }
  }
  