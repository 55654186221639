@import "../../styles/vars";
.globalSearch_button {
  margin: 5px 5px;
  position: relative;
  .btn {
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
.globalSearch {
  margin: 5px 5px;
  position: relative;

  &__close {
    position: absolute;
    top: 0;
    left: calc(100% - 80px);
    height: 25px;
    width: 25px;
    z-index: 100;
  }

  &__results {
    position: relative;
    padding: 0 0 10px 0;

    overflow-y: auto;
    max-height: 340px;
  }
}

.person {
  font-size: 11px;
  margin: 5px;
  padding: 5px;
  border-bottom: 1px solid #eaeaea;
  color: black;
  display: flex;
  flex-direction: row;

  align-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;

    align-content: center;
    align-items: flex-start;
  }

  &:last-child {
    border-bottom: none;
  }

  &__title {
    color: $mustard;
    font-weight: bold;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    width: 250px;

    &--generic {
      &--email {
        color: #0f90fc;
      }
      &--role {
        color: grey;
      }
    }
  }

  &__infoMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    max-width: 150px;

    &--generic {
      &--email {
        color: #0f90fc;
        max-width: 200px;
      }
      &--role {
        color: grey;
      }
    }
  }
  &__address {
    width: 350px;
    margin-right: 10px;
  }
  &__addressMobile {
    max-width: 300px;
    margin-right: 10px;
  }
  &__contact {
    text-align: left;
    width: 150px;
  }
  &__contactMobile {
    text-align: left;
    max-width: 150px;
  }
}
.person:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}
