@import "src/styles/vars";

.upcomingLessonsCard {
  overflow-y: scroll;
}

.upcomingLessons {
  list-style-type: none;
  padding: 0;

  &__lesson {
    text-align: left;
    margin: 5px 0;
    display: flex;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 5px;

    &__order {
      font-weight: bold;
    }

    &__time {
      font-weight: bold;
      min-width: 55px;
    }

    &__room {
      font-style: italic;
      min-width: 150px;
      margin-right: 20px;
    }

    &__title {
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__indicator {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;

    &--trial {
      background-color: $roomPlanLesson;
    }
  }
}
